@use '@angular/material' as mat;

@mixin progressbar-color($back, $front) {
  background-color: $back;
  .determinate, .indeterminate {
    background-color: $front;
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin progressbar-element($color-config) {
    $primary: map-get($color-config, primary);
    $accent:  map-get($color-config, accent);
    $warn:  map-get($color-config, warn);

    // Progress Bar
    position: relative;
    height:   4px;
    display:  block;
    width:    100%;

    border-radius: 2px;
    background-clip: padding-box;
    margin: 0.5rem 0 1rem 0;
    overflow: hidden;

    &, &-primary {
      @include progressbar-color(
        mat.get-color-from-palette($primary, 'lighter'),
        mat.get-color-from-palette($primary, 'default')
      );
    }

    &-primary-dark {
      @include progressbar-color(
        mat.get-color-from-palette($primary, 600),
        mat.get-color-from-palette($primary, 500)
      );
    }

    &-accent {
      @include progressbar-color(
        mat.get-color-from-palette($accent, 'lighter'),
        mat.get-color-from-palette($accent, 'default')
      );
    }

    &-accent-dark {
      @include progressbar-color(
        mat.get-color-from-palette($accent, 'darker'),
        mat.get-color-from-palette($accent, 'default')
      );
    }

    .determinate {
      position: absolute;
      background-color: inherit;
      top: 0;
      bottom: 0;
      transition: width .3s linear;
    }

    .indeterminate {
      &:before {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left:0;
        bottom: 0;
        will-change: left, right;

        // Custom bezier
        animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
      }

      &:after {
        content: '';
        position: absolute;
        background-color: inherit;
        top: 0;
        left:0;
        bottom: 0;
        will-change: left, right;
        // Custom bezier
        animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
        animation-delay: 1.15s;
      }
    }

    @include keyframes(indeterminate) {
      0% {
        left: -35%;
        right:100%;
      }
      60% {
        left: 100%;
        right: -90%;
      }
      100% {
        left: 100%;
        right: -90%;
      }
    }

    @include keyframes(indeterminate-short) {
      0% {
        left: -200%;
        right: 100%;
      }
      60% {
        left: 107%;
        right: -8%;
      }
      100% {
        left: 107%;
        right: -8%;
      }
    }
}
