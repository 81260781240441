@mixin sondermind-system-popup($theme) {
  $primary: map-get($theme, primary);

  .sonder-popup-dialog {
    background: #f6fbfb;
    color: mat.get-color-from-palette($primary, 'default');
    position: relative;
    overflow-y: scroll;
    overflow-wrap: normal;

    box-sizing: border-box;
    max-width: 375px;
    max-height: 375px;
    padding: 30px;

    ul {
        margin: 0;
    }

    @include sm-font-small();

    box-shadow: 0 64px 64px 0 rgba(0, 0, 0, 0.05), 0 32px 32px 0 rgba(0, 0, 0, 0.05), 0 16px 16px 0 rgba(0, 0, 0, 0.05), 0 8px 8px 0 rgba(0, 0, 0, 0.05), 0 4px 4px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .-close {
    position: absolute;
    top: 10px; right: 10px;
    height: 20px; width: 20px;

    font-size: 24px;
    line-height: 20px;
    text-align: center;

    margin: -10px;
    padding: 10px;

    color: rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
        color: rgba(0, 0, 0, 0.3);
    }
  }
}
