@mixin sondermind-system-icon-loader() {

    .sondermind-icon-loader {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .mat-spinner {
            display: inline-block;
            margin-right: 4px;

            width: 1em !important;
            height: 1em !important;

            > svg {
                width: 1em !important;
                height: 1em !important;
            }
        }

    }

}
