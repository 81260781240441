@mixin iris-link(
  $isMobile: false,
  $isQuiet: false) {
  
  text-decoration: none; // reset existing a-tag styles

  // set typography style
  @if $isMobile {
    @include iris-body-mobile;
  } @else {
    @include iris-body;
  }

  @if not $isQuiet {
    text-decoration-line: underline;
  }

  color: $color-action-base; // Note: this overrides the text color from the iris-body mixin.
  
  &:hover {
    color: $color-action-hover;
    text-decoration-line: none;
    cursor: pointer;
  }

  &:focus { 
    outline-color: $color-accessibility-focus-default;
  }

  &:active {
    color: $color-action-active;
  }
}

 @mixin iris-link-responsive($isQuiet: false) {
  @include iris-link($isMobile: true, $isQuiet: $isQuiet);

  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-link($isMobile: false, $isQuiet: $isQuiet);
  }
 }
