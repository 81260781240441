.iris-link-primary-desktop {
  @include iris-link(
    $isMobile: false,
    $isQuiet: false
  );
}

.iris-link-primary-mobile {
  @include iris-link(
    $isMobile: true,
    $isQuiet: false
  );
}

.iris-link-quiet-desktop {
  @include iris-link(
    $isMobile: false,
    $isQuiet: true
  );
}

.iris-link-quiet-mobile {
  @include iris-link(
    $isMobile: true,
    $isQuiet: true
  );
}

.iris-link-primary-responsive {
  @include iris-link-responsive(
    $isQuiet: false
  );
}

.iris-link-quiet-responsive {
  @include iris-link-responsive(
    $isQuiet: true
  );
}
