@use '@angular/material' as mat;
@use './material-mdc-mixins' as material-mdc-mixins;

@import './variables';

@import './components/_buttons.scss';
@import './components/_calendar-mini.scss';
@import './components/_card-carousel.scss';
@import './components/_card-layout.scss';
@import './components/_data-table';
@import './components/_form-field';
@import './components/_icon-label';
@import './components/_icon-loader';
@import './components/journey-header';
@import './components/journey-item';
@import './components/modal';
@import './components/_pagination-control';
@import './components/_participant-pair';
@import './components/_popup';
@import './components/_progress-screen';
@import './components/_task-list';
@import './components/_toast';
@import './components/_typography';
@import './components/_side-nav.scss';

@import url(https://use.typekit.net/bnw6lfh.css);

@mixin install-sondermind-theme() {
  $theme: $sondermind-system-theme;
  $fonts: $sondermind-system-typography;

  * { box-sizing: content-box; }

  // undo the above universal box-sizing
  .iris-button { box-sizing: border-box; }

  $color-config: (
    primary: map-get($theme, primary),
    accent: map-get($theme, accent),
    warn: map-get($theme, warn)
  );

  @include material-mdc-mixins.all-component-themes($color-config, $fonts);
  @include mat.all-legacy-component-typographies();
  @include sondermind-typography-overrides($fonts);

  @include sondermind-system-buttons($theme, $fonts);
  @include sondermind-system-calendar-mini();
  @include sondermind-system-card-carousel($theme, $fonts);
  @include sondermind-system-card-layout($theme, $fonts);
  @include sondermind-system-data-table($theme, $fonts);
  @include sondermind-system-form-field($theme, $fonts);
  @include sondermind-system-icon-label();
  @include sondermind-system-icon-loader();
  @include sondermind-system-journey-header();
  @include sondermind-system-journey-item();
  @include sondermind-system-modal();
  @include sondermind-system-pagination($theme, $fonts);
  @include sondermind-system-participant-pair();
  @include sondermind-system-progress-screen($theme, $fonts);
  @include sondermind-system-popup($theme);
  @include sondermind-system-side-nav();
  @include sondermind-system-task-list();
  @include sondermind-system-toast($theme, $fonts);
  @include sondermind-system-typography-directives($theme, $fonts);
}

@mixin install-sondermind-theme-defaults() {
  $theme: $sondermind-system-theme;
  
  color: mat.get-color-from-palette(map-get($theme, foreground), 'default');
  background: mat.get-color-from-palette(map-get($theme, background), 'default');
}

@mixin sondermind-typography-overrides($fonts) {
  &.sondermind-design-typography {
    h1, h2, h3, h4 {
      // changed from material default of 16
      margin: 0 0 8px;
    }

    h5 {
      @include sondermind-typography-level-to-styles($fonts, subheading-0);
    }
  }
}