@mixin sondermind-system-participant-pair() {
    .participant-pair {
        align-items: center;
        display: flex;
        margin-bottom: 64px;
        max-width: 364px;
        padding: 30px 12px;

        &[data-size="l"] {
            mat-icon {
                font-size: 32px;
            }
        }

        &[data-size="mega"] {
            max-width: 580px;
        }

        > * {
            flex: 1 1 0px;
        }

        mat-icon {
            align-items: center;
            color: #004455;
            display: flex;
            font-size: 44px;
            justify-content: center;
        }
    }
}

:host ::ng-deep sondermind-profile-picture.initials {
    font-size: 60px;
}
