@import '1-tokens/-index'; // design tokens
@import '2-tools/_tools.helpers'; // helper functions & mixins
@import '2-tools/_tools.breakpoints'; // breakpoint specific functions & mixins

// Variable $-cdk-overlay is unused, but is here to show the relationship between our
// layers and unmodified cdk-overlays like mat-select, datepickers, and Chat Modal.
// From Angular CDK:
//    $overlay-z-index: 1000 !default;
//    https://github.com/angular/components/blob/12.2.13/src/cdk/overlay/_index.scss
$-cdk-overlay: 1000;
$-above-navigation: 800;
$-navigation: 600;
$-below-navigation: 400;

// The standard overlay layer covers nav on Desktop, but will be covered by nav on mobile.
// e.g. sonder-modal, VideoSessionOverlay (sidebar)
@mixin standard-overlay() {
  @include iris-media(">1024px") {
    z-index: $-above-navigation;
  }

  @include iris-media("<=1024px") {
    z-index: $-below-navigation;
  }
}

// Gives access to the $-cdk-overlay variable if necessary
@mixin cdk-override() {
  z-index: $-cdk-overlay;
}

// The fullscreen overlay layer will always cover nav - even on mobile.
// e.g. VideoSessionOverlay (compact, fullscreen)
@mixin fullscreen-overlay() {
  z-index: $-above-navigation;
}

@mixin navigation() {
  z-index: $-navigation;
}

@mixin sticky-headers() {
  z-index: $-below-navigation - 1;
}
