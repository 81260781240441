// hover gets the lighter accent background and black text
@mixin sondermind-button-hover-mixin($palette) {
  &:hover {
    text-decoration: none;
  }

  &:not([disabled]):hover {
    color: black;
    border-color: mat.get-color-from-palette($palette, 'lighter');
    background: mat.get-color-from-palette($palette, 'lighter');

    .mat-icon {
      color: black;
    }
  }
}

@mixin sondermind-system-buttons($theme, $font) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $background: map-get($theme, background);

  $button-padding: 16px;
  $button-line-height: 48px;
  $stroked-border-width: 2px;

  .sondermind-button-row {
    box-sizing: border-box;

    > .sondermind-button {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }

    &[data-mobile="true"], &[data-mobile=""] {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 8px;

      > .sondermind-button {
        flex: 1 1 auto;
        min-width: initial;
      }

      &[data-count="2"] {
        > .sondermind-button { max-width: 48%; }
      }

      &[data-count="3"] {
        > .sondermind-button { max-width: 31%; }
      }
    }
  }

  button.sondermind-button-icon, a.sondermind-button-icon {
      &[data-mobile="true"] {
          width: 34px;
          height: 34px;
          line-height: 34px;
      }

      &[disabled] .mat-icon.mat-primary {
        color: mat.get-color-from-palette($background, 'disabled-button');
      }
  }

  button.sondermind-button, a.sondermind-button {
    @include mat.typography-level($font, 'button');
    text-transform: uppercase;

    // sizing
    min-width: 130px;
    line-height: $button-line-height;
    padding: 2px $button-padding 0;

    // animations are good
    transition: $animations-sondermind-transition;

    // automatic separation
    & + & {
      margin-left: 16px;
    }

    &.sondermind-button-primary {
      @include sondermind-button-hover-mixin($accent)
    }

    &.sondermind-button-secondary {
      // increase the border width on strokes
      border-width: $stroked-border-width;
      line-height: $button-line-height - (2 * $stroked-border-width);
      padding: 2px $button-padding - (2 * $stroked-border-width) 0;

      // filled in when disabled
      &[disabled] {
        background: mat.get-color-from-palette($background, 'disabled-button');
        border-color: mat.get-color-from-palette($background, 'disabled-button');
      }

      @include sondermind-button-hover-mixin($accent)
    }

    &.sondermind-button-tertiary {
      display: inline-block;
      padding: 4px 6px 2px;
      min-width: 0;

      line-height: 18px;
      vertical-align: middle;

      .mat-icon {
        line-height: 18px;
      }

      &:not([disabled]):hover {
        .mat-icon {
          transition: $animations-sondermind-transition;

          &.mat-primary {
            color: mat.get-color-from-palette($accent, 'default');
          }

          &.mat-accent {
            color: mat.get-color-from-palette($primary, 'default');
          }
        }
      }
    }

    &.wrap-button-text {
      line-height: 24px;
      margin: 0 auto;
      padding: 12px;
      white-space: normal;
      width: 100%;
    }

    // icons in buttons are slightly smaller;
    .mat-icon {
      font-size: 20px;
      line-height: 20px;

      // reduce the size of non-inline icons
      &:not(.mat-icon-inline) {
        height: 20px;
        width: 20px;
      }

      // proxima nova is short, fix icon alignment
      position: relative;
      top: -1.5px;

      // leading icons percept too close, space out slightly
      margin-right: 2px;
      &:last-child {
        margin-right: 0;
      }
    }

    // icon should get it's color from disabled buttons
    &[disabled] .mat-icon {
      color: inherit;
    }

    // chevrons have bad weight, need font-size-increase
    &.sondermind-button-nav {
      .mat-icon {
        font-size: 24px;
      }
    }
  }
}
