@mixin sondermind-system-icon-label() {

    .sondermind-icon-label {

        display: flex;
        flex-direction: row;
        align-items: center;

        &[data-valign='baseline'] {
          align-items: baseline;
        }

        .mat-icon:last-child {
            padding-left: 8px;
        }

        .mat-icon:first-child {
            padding-right: 8px;
        }

    }

}
