@use 'sass:math';
@use '@angular/material' as mat;

@import 'mixins';

@mixin sondermind-system-card-carousel($theme, $font) {
  $background: map-get($theme, background);

  // width is hard coded into the scroll container
  // if we change it, it needs to change there too
  $width:  270px;
  $height: 150px;
  $radius: 4px;

  $gutter: 8px;
  $card-padding: 10px 16px;
  $container-padding: 2px;

  .sondermind-card-carousel {
      display: block;
      position: relative;
      width: 100%;

      ol {
          list-style: none;
          margin: 0; padding: 8px;

          @include sondermind-typography-level-to-styles($font, card-carousel-body);

          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          overflow-x: scroll;
          scroll-snap-type: x mandatory;

          li {
              flex: 0 0 auto;

              display: flex;
              flex-direction: column;

              width: $width;
              min-height: $height;

              box-sizing: border-box;
              margin: 0 math.div($gutter, 2);
              padding: $card-padding;

              background: mat.get-color-from-palette($background, 'card-carousel-item');
              border-radius: $radius;

              scroll-snap-align: center;

              &[data-spacer] {
                  width: 4px;
                  padding: 0;
                  visibility: hidden;
              }

              > header {
                  flex: 0 0 auto;
                  margin-bottom: 16px;

                  display: flex;
                  flex-direction: row;
                  align-items: center;
              }

              > footer {
                  flex: 0 0 auto;
                  margin-top: 16px;

                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  > [data-pagination] {
                      flex: 0 0 auto;
                      @include sondermind-typography-level-to-styles($font, caption);
                  }

                  > [data-content] {
                      flex: 1 0 auto;
                  }

                  > [data-navigation] {
                      flex: 0 0 auto;
                      line-height: 0;
                  }
              }

              > section {
                  flex: 1 1 auto;
              }
          }
      }

      // shadows
      &[data-shadows='true'], &[data-shadows=''] {
          ol {
              &:before, &:after {
                  display: block;
                  content: '';

                  position: absolute;
                  top: -2px; bottom: -2px;
                  width: 50px;

                  opacity: 1;
                  will-transform: opacity;
                  transition: 50ms opacity ease-in;
              }

              &:before {
                  left: 0;
                  border-radius: 2px 0 0 2px;
                  box-shadow: 5px 0 5px -7px inset black;
              }

              &:after  {
                  right: 0;
                  border-radius: 0 2px 2px 0;
                  box-shadow: -5px 0 5px -7px inset black;
              }
          }

          &[data-flush~='left'] {
              ol:before { opacity: 0; }
          }

          &[data-flush~='right'] {
              ol:after { opacity: 0; }
          }
      }
  }
}
