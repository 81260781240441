@use '@angular/material' as mat;

@mixin sondermind-system-typography-directives($theme, $font) {
  $primary:           map-get($theme, primary);
  $accent:            map-get($theme, accent);
  $success:           map-get($theme, success);
  $warning:           map-get($theme, warn);
  $error:             map-get($theme, error);
  $foreground:        map-get($theme, foreground);
  $foreground-invert: map-get($theme, foreground-invert);
  $background:        map-get($theme, background);

  [nowrap], [data-nowrap] {
    white-space: nowrap;
  }

  hr {
      width: 100%;
      margin: 1.2em 0;
      border: 0;
      border-bottom: 1px solid mat.get-color-from-palette($background, 'hrule');
  }

  br[data-separator] {
    display: block;
    height: 0;
    line-height: 0;
    margin-top: 20px;
  }

  .mat-icon[data-inline-fix="true"] {
    position: relative;
    top: 0.15em;
    margin-right: 0.15em;
  }

  .mat-icon[data-error-fix] {
    font-size: 20px
  }

  .mat-icon[data-badged] {
    position: relative;

    &:after {
        display: block;
        content: '';
        width: 8px; height: 8px;

        border-radius: 100%;
        position: absolute;
        top: 2px; right: 4px;
        z-index: 5;
    }

    &[data-badged="true"]:after {
        background: mat.get-color-from-palette($accent, 'default');
    }
  }

  .sondermind-typography {
    &[data-theme='primary'] { color: mat.get-color-from-palette($primary, 200) !important; }
    &[data-theme='accent'] { color: mat.get-color-from-palette($accent, 'default') !important; }
    &[data-theme='light'] { color: mat.get-color-from-palette($foreground, 'base') !important; }
    &[data-theme='dark'] { color: mat.get-color-from-palette($foreground-invert, 'base') !important; }
    &[data-theme='success'] { color: mat.get-color-from-palette($success, 'default') !important; }
    &[data-theme='warning'] { color: mat.get-color-from-palette($warning, 'default') !important; }
    &[data-theme='error'] { color: mat.get-color-from-palette($error, 'default') !important; }
    &[data-theme='disabled'] { color: rgba(0, 0, 0, 0.333) !important; }

    &[data-align='left'] { text-align: left; }
    &[data-align='center'] { text-align: center; }
    &[data-align='right'] { text-align: right; }

    &[data-float='left'] { float: left; }
    &[data-float='right'] { float: right; }

    &[data-overflow='ellipsis'] {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &[data-overflow='hidden'] {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: hidden;
    }

    &[data-width='full'] {
        box-sizing: border-box;
        width: 100%;
    }

    &[data-width='auto'] {
        width: auto;
    }

    &[data-effect='faded'] {
        opacity: 0.5;
    }

    &[data-effect='strong'] {
        font-weight: bold !important;
    }

    a {
      @include sondermind-typography-level-to-styles($font, link);
      color: currentColor;
    }

    &[data-type='link'] {
      @include sondermind-typography-level-to-styles($font, link);
      color: currentColor;
      text-decoration: underline;
    }

    &[data-type='hero'] {
      margin: 0 0 16px 0;
      @include sondermind-typography-level-to-styles($font, headline);

      &[data-mobile='true'] {
        @include sondermind-typography-level-to-styles($font, headline-mobile);
      }
    }

    &[data-type='metric'] {
      @include sondermind-typography-level-to-styles($font, title);
      color: mat.get-color-from-palette($primary, 200);
      margin: 0;

      &[data-mobile='true'] {
        @include sondermind-typography-level-to-styles($font, subheading-2);
      }
    }

    &[data-type='legal'] {
      @include sondermind-typography-level-to-styles($font, legal);

      &[data-mobile='true'] {
        @include sondermind-typography-level-to-styles($font, legal-mobile);
      }
    }

    &[data-type='badge'] {
        @include sondermind-typography-level-to-styles($font, legal);

        position: relative;
        color: white !important;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%; left: 50%;
            width: 20px; height: 20px;
            transform-origin: center;
            transform: translate(-50%, -50%);
            border-radius: 100%;
            z-index: -1;
        }

        &[data-theme='primary']::before { background-color: mat.get-color-from-palette($primary, 'default') !important; }
        &[data-theme='accent']::before { background-color: mat.get-color-from-palette($accent, 'default') !important; }
        &[data-theme='success']::before { background-color: mat.get-color-from-palette($success, 'default') !important; }
        &[data-theme='warning']::before { background-color: mat.get-color-from-palette($warning, 'default') !important; }
        &[data-theme='error']::before { background-color: mat.get-color-from-palette($error, 'default') !important; }
    }
  }

  h3.sondermind-typography[data-mobile='true'] {
    @include sondermind-typography-level-to-styles($font, subheading-2-mobile);
  }

  figure.sondermind-typography[data-type='metric'] {
    margin: 0 0 16px;

    @include sondermind-typography-level-to-styles($font, body-1);

    // when not themed, make the numbers smaller
    &:not([data-theme]) {
      color: mat.get-color-from-palette($primary, 600);

      h2, small {
        color: mat.get-color-from-palette($primary, 200);
      }
    }

    // big numbers
    h2 {
      @include sondermind-typography-level-to-styles($font, title);
      margin: 0;
    }

    &[data-mobile='true'] {
      @include sondermind-typography-level-to-styles($font, body-small);

      h2 {
        @include sondermind-typography-level-to-styles($font, subheading-2);
      }
    }

    // snippets of text
    p {
      margin: 0;
    }

    // disclosures
    small {
      display: block;
      max-width: 240px;

      @include sondermind-typography-level-to-styles($font, card-layout-small);
    }

    // title
    figcaption {
      @include sondermind-typography-level-to-styles($font, link);
      margin: 8px 0 0;

      a {
        color: currentColor;
        text-decoration: underline;
        text-decoration-color: mat.get-color-from-palette($primary, 200, 0.4);
      }
    }
  }

  @at-root {
    .figure-row {
      display: flex;
      flex-direction: row;

      figure.sondermind-typography[data-type='metric'] {
        display: flex;
        flex-direction: row;

        align-items: center;
        &[data-mobile="true"] {
          align-items: baseline;
        }

        flex: 0 0 auto;

        &:not(:last-child) {
          padding-right: 40px;
        }

        h2 {
          padding-right: 8px;
        }
      }
    }
  }

}
