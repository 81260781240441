@use '@angular/material' as mat;

@import 'design-tokens';
@import 'mixins';

$animations-sondermind-duration: 200ms !default;
$animations-sondermind-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$animations-sondermind-transition: all $animations-sondermind-duration $animations-sondermind-function !default;

// COLORS
$palette-sondermind-blue: mat.define-palette((
  100: #000,
  200: #002532,
  500: #045,    // default, darker, lighter
  600: #124c60,
  contrast: (
    100: white,
    200: white,
    500: white,
  )
), 500, 500, 500);

$palette-sondermind-grey: mat.define-palette((
  100: #9b9b9b,
  250: #a0a0a0,
  300: #adadad, // darker
  400: #d8d8d8,
  500: #ececec, // default
  600: #f3f3f3, // lighter
  650: #f8f8f8,
  700: #fafafa,
  contrast: (
    100: $color-brand-1-base,
    250: $color-brand-1-base,
    300: $color-brand-1-base,
    400: $color-brand-1-base,
    500: $color-brand-1-base,
    600: $color-brand-1-base,
    650: $color-brand-1-base,
    700: $color-brand-1-base,
  )
), 500, 600, 300);

$palette-sondermind-orange: mat.define-palette((
  300: #f08060, // darker
  500: #f99f7e, // default
  700: #ffb499, // lighter
  contrast: (
    300: $color-brand-1-base,
    500: $color-brand-1-base,
    700: $color-brand-1-base,
  )
), 500, 700, 300);

$palette-sondermind-error: mat.define-palette((
  500: #b93646, // default, lighter, darker
  contrast: (
    500: white
  )
), 500, 500, 500);

$palette-sondermind-warning: mat.define-palette((
  500: #fcbf38, // default, lighter, darker
  contrast: (
    500: $color-brand-1-base,
  )
), 500, 500, 500);

$palette-sondermind-success: mat.define-palette((
  500: #048106, // default, lighter, darker
  contrast: (
    500: $color-brand-1-base,
  )
), 500, 500, 500);

$palette-sondermind-info: mat.define-palette((
  500: #66c4de, // default, lighter, darker
  contrast: (
    500: $color-brand-1-base,
  )
), 500, 500, 500);

// manually unroll mat-light-theme, so we get to set fore/backgrounds
$sondermind-system-theme: (
  primary: $palette-sondermind-blue,
  accent: $palette-sondermind-orange,
  success: $palette-sondermind-success,
  warn: $palette-sondermind-warning,
  error: $palette-sondermind-error,
  notification: $palette-sondermind-grey,
  is-dark: false,
  info: $palette-sondermind-info,

  foreground: map-merge(mat.$light-theme-foreground-palette, (
    default: map-get($palette-sondermind-blue, 200),

    calendar-mini-day: map-get($palette-sondermind-blue, 200),
    calendar-mini-past: map-get($palette-sondermind-grey, 400),
    calendar-mini-other: map-get($palette-sondermind-grey, 400),

    form-field: map-get($palette-sondermind-grey, 250),
    progress-screen: map-get($palette-sondermind-blue, 200),

    side-nav: white,
  )),

  foreground-invert: map-merge(mat.$dark-theme-foreground-palette, (
  )),

  background: map-merge(mat.$light-theme-background-palette, (
    default: white,

    calendar-mini-active-bg: map-get($palette-sondermind-orange, 500),
    calendar-mini-highlight-start-bg: map-get($palette-sondermind-orange, 700),
    calendar-mini-highlight-end-bg: map-get($palette-sondermind-orange, 700),
    calendar-mini-highlight-span-bg: transparentize(map-get($palette-sondermind-orange, 700), 0.5),
    connected-calendar-hover: map-get($palette-sondermind-grey, default),

    card-layout-item: white,
    card-layout-item-highlight: map-get($palette-sondermind-grey, 650),
    card-layout-item-highlight-dark: map-get($palette-sondermind-grey, 600),
    card-layout-item-separator: map-get($palette-sondermind-grey, 400),
    card-carousel-item: map-get($palette-sondermind-grey, 600),
    disabled-button: map-get($palette-sondermind-grey, darker),
    form-field: map-get($palette-sondermind-grey, lighter),
    hrule: map-get($palette-sondermind-grey, darker),
    side-nav: map-get($palette-sondermind-blue, default),

    task-list-item-highlight: map-get($palette-sondermind-grey, 650),
  )),

  background-invert: map-merge(mat.$dark-theme-background-palette, (
    background: $color-brand-1-base
  )),

  data-table: (
    hover-border: map-get($palette-sondermind-orange, 700),
    zebra-stripe: map-get($palette-sondermind-grey, 650),
    footer-border: map-get($palette-sondermind-grey, 400)
  ),

  chat: (
      message-foreground-text: map-get($palette-sondermind-blue, 200),
      message-foreground-icon: $color-brand-1-base,
      message-background-mine: transparentize($color-brand-1-base, 0.9),
      message-background-theirs: map-get($palette-sondermind-grey, 650),
      message-separator-border: transparentize(map-get($palette-sondermind-blue, 200), 0.9)
  )
);

// TYPOGRAPHY
$sondermind-system-typography: map-merge(
  mat.define-legacy-typography-config(
    $font-family: 'proxima-nova, sans-serif',

    $display-1: mat.define-typography-level(50px, 1.2, 300, 'museo-slab', $letter-spacing: 1.5px),

    // h1, h2, h3, h4
    $headline:      mat.define-typography-level(42px, 1.1, 300, 'museo-slab', $letter-spacing: 0.88px),
    $title:         mat.define-typography-level(32px, 1.16, 300, 'museo-slab', $letter-spacing: 0.5px),
    $subheading-2:  mat.define-typography-level(24px, normal, 300, 'museo-slab', $letter-spacing: 0.5px),
    $subheading-1:  mat.define-typography-level(18px, 1.2, 500, 'museo-slab'),

    // normal, bold
    $body-1:        mat.define-typography-level(16px, 1.31, 400),
    $body-2:        mat.define-typography-level(16px, 32px, 600),

    // small
    $caption:       mat.define-typography-level(10px, normal),

    // buttons and links
    $button:        mat.define-typography-level(14px, normal, 600, $letter-spacing: 1.75px),

    // form inputs
    $input:         mat.define-typography-level(14px, 1.7, 400)
  ),
  (
    subheading-0:                  sondermind-typography-level(15px, normal, 500, 'museo-slab', $letter-spacing: 0, $normalcase: true),
    body-small:                    sondermind-typography-level(14px, 16px, 300, 'proxima-nova'),

    link:                          sondermind-typography-level(14px, normal, 500, 'proxima-nova', $letter-spacing: 0, $normalcase: true),
    label:                         sondermind-typography-level(14px, 18px, 700, 'proxima-nova', $letter-spacing: 2px, $upcase: true),

    legal:                         sondermind-typography-level(12px, normal, 400, 'proxima-nova'),
    legal-mobile:                  sondermind-typography-level(10px, normal, 400, 'proxima-nova'),

    form-helptext:                 sondermind-typography-level(12px, 1.2, 500, 'proxima-nova', $letter-spacing: 0, $normalcase: true),

    headline-mobile:               sondermind-typography-level(28px, 1.1, 300, 'museo-slab'),
    subheading-2-mobile:           sondermind-typography-level(20px, 1.8, 300, 'museo-slab'),

    card-carousel-body:            sondermind-typography-level(14px, 1.3, 400),

    card-layout-header:            sondermind-typography-level(16px, 1.5, 600, 'proxima-nova', $letter-spacing: 1px, $upcase: true),
    card-layout-header-mobile:     sondermind-typography-level(14px, normal, 600, 'proxima-nova', $letter-spacing: 0.93px, $upcase: true),
    card-layout-header-small:      sondermind-typography-level(12px, 1.5, 600, 'proxima-nova', $letter-spacing: 0, $normalcase: true),
    card-layout-header-control:    sondermind-typography-level(14px, 2, 700, 'proxima-nova', $letter-spacing: 0.56px, $upcase: true),
    card-layout-small:             sondermind-typography-level(12px, 1.2, 500, 'proxima-nova', $letter-spacing: 0),

    data-table-mobile:             sondermind-typography-level(14px, normal, 400, $letter-spacing: 0),
    data-table-menu:               sondermind-typography-level(14px, normal, 400),
    data-table-summary-figure:     sondermind-typography-level(20px, normal, 500, 'museo-slab'),
    data-table-summary-secondary:  sondermind-typography-level(15px, normal, 500, 'museo-slab'),
    data-table-summary-caption:    sondermind-typography-level(14px, normal, 600, 'proxima-nova', $letter-spacing: 0.93px, $upcase: true),

    pagination-controls:           sondermind-typography-level(18px, normal, 600, $letter-spacing: 2px),

    chat-message-item:             sondermind-typography-level(14px, 16px, 400, 'proxima-nova'),
    chat-message-timestamp:        sondermind-typography-level(12px, 16px, 400, 'proxima-nova'),
    chat-message-separator:        sondermind-typography-level(10px, 16px, 600, 'proxima-nova'),

    chat-room-header:              sondermind-typography-level(14px, 16px, 500, 'proxima-nova', $letter-spacing: 0.08em, $upcase: true),
    chat-room-list-timestamp:      sondermind-typography-level(10px, 12px, 400, 'proxima-nova')
  )
);

@mixin sm-font-small() {
  font: 500 14px/24px 'proxima-nova';
}
