@import '1-tokens/-index'; // design tokens
@import '2-tools/_tools.helpers'; // helper functions & mixins

@mixin sondermind-system-modal() {
  ::ng-deep .sondermind-modal-fullscreen .mat-dialog-container {
    @include iris-media("<=1024px") {
      margin-left: 0px;
      width: 320px;
      height: 100%;
    }
  }
}
