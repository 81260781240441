// Imports proxima-nova, museo-sans, and museo-slab fonts 
@import url("https://use.typekit.net/fuz2lza.css");

$default-type-color: $color-neutral-default;

@mixin text-defaults {
  font-family: $font-family-proxima-nova, $font-family-fallback, sans-serif;
}

// Desktop styles
@mixin iris-h1-desktop($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-09;
  line-height: $font-line-height-10;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-01;
}

@mixin iris-h2-desktop($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-07;
  line-height: $font-line-height-09;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-01;
}

@mixin iris-h3-desktop($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-06;
  line-height: $font-line-height-07;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-00;
}

@mixin iris-h4-desktop($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-05;
  line-height: $font-line-height-06;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-00;
}

@mixin iris-body($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-04;
  line-height: $font-line-height-05;
  font-weight: $font-weight-normal;
  letter-spacing: $font-letter-spacing-01;
}

@mixin iris-body-bold($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-04;
  line-height: $font-line-height-05;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-00;
}

@mixin iris-legal($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-02;
  line-height: $font-line-height-02;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-02;
}

@mixin iris-button-text($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-04;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-03;
}

// Mobile styles
@mixin iris-h1-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-08;
  line-height: $font-line-height-08;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-01;
}

@mixin iris-h2-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-07;
  line-height: $font-line-height-07;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-01;
}

@mixin iris-h3-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-06;
  line-height: $font-line-height-06;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-00;
}

@mixin iris-h4-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-04;
  line-height: $font-line-height-04;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-00;
}

@mixin iris-body-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-03;
  line-height: $font-line-height-03;
  font-weight: $font-weight-normal;
  letter-spacing: $font-letter-spacing-01;
}

@mixin iris-body-bold-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-03;
  line-height: $font-line-height-03;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-00;
}

@mixin iris-legal-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-01;
  line-height: $font-line-height-01;
  font-weight: $font-weight-semibold;
  letter-spacing: $font-letter-spacing-02;
}

@mixin iris-button-text-mobile($color: $default-type-color) {
  @include text-defaults;
  color: $color;
  font-size: $font-size-04;
  font-weight: $font-weight-bold;
  letter-spacing: $font-letter-spacing-04;
}

// Responsive styles.
// Add 1px to breakpoints since we're not using iris-media,
// and `min-width` === `>=`, but we want to use `>`.

@mixin iris-h1-responsive($color: $default-type-color) {
  @include iris-h1-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-h1-desktop($color: $color);
  }
}

@mixin iris-h2-responsive($color: $default-type-color) {
  @include iris-h2-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-h2-desktop($color: $color);
  }
}

@mixin iris-h3-responsive($color: $default-type-color) {
  @include iris-h3-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-h3-desktop($color: $color);
  }
}

@mixin iris-h4-responsive($color: $default-type-color) {
  @include iris-h4-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-h4-desktop($color: $color);
  }
}

@mixin iris-body-responsive($color: $default-type-color) {
  @include iris-body-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-body($color: $color);
  }
}

@mixin iris-body-bold-responsive($color: $default-type-color) {
  @include iris-body-bold-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-body-bold($color: $color);
  }
}

@mixin iris-legal-responsive($color: $default-type-color) {
  @include iris-legal-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-legal($color: $color);
  }
}

@mixin iris-button-text-responsive($color: $default-type-color) {
  @include iris-button-text-mobile($color: $color);
  
  $min-width-breakpoint: ($breakpoint-xs + 1px);
  @media (min-width: $min-width-breakpoint) { 
    @include iris-button-text($color: $color);
  }
}

// Modal styles

@mixin iris-modal-h1-responsive($color: $default-type-color) {
  @include iris-h2-responsive($color: $color);
}

@mixin iris-modal-h2-responsive($color: $default-type-color) {
  @include iris-h3-responsive($color: $color);
}

@mixin iris-modal-h3-responsive($color: $default-type-color) {
  @include iris-h4-responsive($color: $color);
}