@mixin sondermind-system-journey-item() {
    $status-dot-size: 10px;
    $icon-size: 22px;

    .journeyItem {
        align-items: center;
        display: flex;
        margin-bottom: 10px;

        .statusDot {
            background-color: mat.get-color-from-palette($palette-sondermind-grey, 300);
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: $status-dot-size;
            margin-right: 1rem;
            position: relative;
            width: $status-dot-size;
            z-index: 2;
        }

        .mat-icon {
            align-items: center;
            color: rgba(0, 0, 0, 0.3);
            display: inline-flex;
            font-size: $icon-size;
            height: $icon-size;
            margin-right: 10px !important;
            top: 0 !important;
        }

        p {
            color: mat.get-color-from-palette($palette-sondermind-grey, 250);
            display: inline;
            font-size: 14px;
            margin-bottom: 0;
        }


        &.-statusOngoing, &.-statusComplete {
            .mat-icon {
                color: #004455;
            }

            p {
                color: mat.get-color-from-palette($palette-sondermind-blue, 200);
            }
        }

        &.-statusOngoing .statusDot {
            background-color: #efb024;
        }

        &.-statusComplete .statusDot {
            background-color: mat.get-color-from-palette($palette-sondermind-success, 500);
            &:before {
                content: "check";
                color: white;
                font-size: $status-dot-size ;
                position: absolute;
            }
        }
    }
}
