@use '@angular/material' as mat;

@function sondermind-typography-level(
  $size,
  $line-height,
  $weight,
  $family: null,
  $letter-spacing: null,
  $upcase: false,
  $downcase: false,
  $normalcase: false,
) {
  $level: mat.define-typography-level($size, $line-height, $weight, $family, $letter-spacing: $letter-spacing);
  @return map-merge($level, (upcase: $upcase, downcase: $downcase, normalcase: $normalcase));
}

@mixin sondermind-typography-level-to-styles($level, $name) {
  @include mat.typography-level($level, $name);

  $level: map-get($level, $name);

  @if map-get($level, upcase) {
    text-transform: uppercase;
  }

  @if map-get($level, downcase) {
    text-transform: lowercase;
  }

  @if map-get($level, normalcase) {
    text-transform: initial;
  }
}
