@use '@angular/material' as mat;

@import 'mixins';

@mixin sondermind-system-progress-screen($theme, $font) {
  $foreground: map-get($theme, foreground);

  .sondermind-progress-screen {
    display: block;
    position: relative;
    width: 100%; height: 100%;

    [data-screen] {
      position: absolute;
      top: 0; left: 0; right: 0; bottom: 0;
      z-index: 100;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > * {
        flex: 0 0 auto;
        margin: 8px;
      }

      @include sondermind-typography-level-to-styles($font, label);
      color: mat.get-color-from-palette($foreground, 'progress-screen');
      background: rgba(255, 255, 255, 0.9);
    }
  }

}
