@import '1-tokens/-index'; // design tokens

@mixin sondermind-system-task-list() {
  .sondermind-task-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    [data-empty] {
      // Add height to prevent overflow: scroll on provider dashboard from kicking in
      height: 100px;
      margin-top: $spacing-03;
    }

    .sondermind-task-list-item {
      align-items: center;
      border: 1px solid transparent;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      padding: 0px 12px 0px 24px;

      .task-list-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 56px;
        padding: $spacing-01 $spacing-00;
      }

      &:hover, &:focus {
        border-color: $color-brand-1-hover;
        outline: none;
      }

      &:nth-child(2n + 1) {
        background: $color-neutral-03;
      }

      [data-title] {
        @include iris-body-bold-responsive;
      }

      [data-priority] {
        @include iris-legal-mobile;
        color: $color-message-error-base;
        margin: $spacing-00;
      }
    }
  }
}
