@mixin sondermind-system-journey-header() {
    .journeyHeader {
        h3 {
            margin-bottom: 1rem !important;
        }

        .journeyProgress {
            position: relative;

            p {
                line-height: 1.31;
            }

            .statusDotConnector {
                background-color: mat.get-color-from-palette($palette-sondermind-grey, 300);
                content: "";
                height: calc(100% - 1rem);
                left: 4px;
                position: absolute;
                top: .5rem;
                width: 2px;
            }
        }
    }
}
