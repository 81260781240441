@import 'mixins';

@mixin sondermind-system-pagination($theme, $font) {
  $primary: map-get($theme, primary);

  ul.sondermind-pagination {
    margin: 0; padding: 0;
    list-style-type: none;

    li {
      display: inline;

      .sondermind-button, .mat-icon {
        color: mat.get-color-from-palette($primary, 'default');
        @include sondermind-typography-level-to-styles($font, pagination-controls);

        min-width: 35px;
        text-align: center;
      }

      .pagination-links {
        opacity: 0.3;
      }

      .pagination-links.selected {
        opacity: 1;
      }
    }
  }
}
