@mixin sonder-mobile-modal-height($header-height) {
  sonder-modal .sonder-modal.mobile#sonder-modal {
    height: calc(100% - #{$header-height});
    margin-top: calc(#{$header-height} / 2);

    &.in-native-app {
      height: 100%;
      margin-top: 0px;
    }
  }

  sonder-modal.full-screen-on-mobile .sonder-modal.mobile#sonder-modal {
    height: 100%;
    margin-top: 0px;
  }
}
