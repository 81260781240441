/// Read a list of media query expressions and separate logical disjunctions into different branches

// Note: you may think that the parameter should be `$expressions...` since it could be a list, but
// that breaks the functionality. Best to leave this old code alone. 
// Unit tests target `iris-media` and treat this like a private function.
@function get-query-branches($expressions) {
  $result: '';
  $has-groups: false;

  // Get initial snapshot and look for groups
  @each $expression in $expressions {

    @if (str-length($result) != 0) {
      $result: $result + ' and ';
    }

    @if (type-of($expression) == 'string') {
      $result: $result + $expression;
    }

    @else if (type-of($expression) == 'list') {
      $result: $result + nth($expression, 1);
      $has-groups: true;
    }

    @else {
      @warn '#{$expression} is not a valid expression.';
    }
  }

  // If we have groups, we have to create all possible combinations
  @if $has-groups {
    @each $expression in $expressions {
      @if (type-of($expression) == 'list') {
        $first: nth($expression, 1);

        @each $member in $expression {
          @if ($member != $first) {
            @each $partial in $result {
              $result: join($result, str-replace-first($first, $member, $partial));
            }
          }
        }
      }
    }
  }

  @return $result;
}

/// Parse a string to form a media query expression
@function parse-expression($expression) {
  $operator: ''; // https://sass-lang.com/documentation/operators
  $value: '';
  $element: '';
  $result: '';
  $is-width: true;

  @if (type-of($expression) != 'string') {
    @warn 'Expression with type `#{type-of($expression)}` detected, string expected.';
  }

  // Separate the operator from the rest of the expression
  @if (str-slice($expression, 2, 2) == '=') {
    $operator: str-slice($expression, 1, 2);
    $value: str-slice($expression, 3);
  }

  @else {
    $operator: str-slice($expression, 1, 1);
    $value: str-slice($expression, 2);
  }

  // If we can fetch a breakpoint token, this is a breakpoint
  @if(deep-map-get($tokens, 'breakpoint', $value) ) {     
    $result: deep-map-get($tokens, 'breakpoint', $value);
  }

  // if it not, check if it is an expression
  @else if (deep-map-get($tokens, 'media-query', 'expression', $expression)) {
    $result: deep-map-get($tokens, 'media-query', 'expression', $expression);
    $is-width: false;
  } 
  // If it's neither of the previous, it's a number
  @else {
    $result: to-number($value);
  }

  @if ($is-width) {
    $unit: unit($result);
    $interval: 0;

    @if (deep-map-check($tokens, media-query, unit-intervals, $unit)) {
      $interval: deep-map-get($tokens, media-query, unit-intervals, $unit);
    }

    @else {
      @warn 'Unknown unit: #{$unit}';
    }

    @if ($operator == '>') {
      $element: '(min-width: #{$result + $interval})';
    }

    @else if ($operator == '<') {
      $element: '(max-width: #{$result - $interval})';
    }

    @else if ($operator == '>=') {
      $element: '(min-width: #{$result})';
    }

    @else if ($operator == '<=') {
      $element: '(max-width: #{$result})';
    }

    @else {
      @warn '#{$expression} is missing an operator.';
    }
  }

  @else {
    $element: $result;
  }

  @return $element;
}

// Replace the first occurrence of $search in $subject with $replace
// All params are strings
@function str-replace-first($search, $replace, $subject) {
  $search-start: str-index($subject, $search);

  @if $search-start == null {
    @return $subject;
  }

  $result: str-slice($subject, 0, $search-start - 1);
  $result: $result + $replace;
  $result: $result + str-slice($subject, $search-start + str-length($search));

  @return $result;
}

// Docs on available units: https://www.w3.org/Style/Examples/007/units.en.html
@function _length($number, $unit) {
  $strings: 'px' 'cm' 'mm' '%' 'ch' 'in' 'em' 'rem' 'pt' 'pc' 'ex' 'vw' 'vh' 'vmin' 'vmax';
  $units:   1px  1cm  1mm  1%  1ch  1in  1em  1rem  1pt  1pc  1ex  1vw  1vh  1vmin  1vmax;
  $index: index($strings, $unit);

  // Error if unit type is unknown
  @if type-of($index) != 'number' {
    @warn 'Unknown unit `#{$unit}`.';

    @return false;
  }

  @return $number * nth($units, $index);
}

@mixin iris-media($conditions...) {
  @for $i from 1 through length($conditions) {
    // set-nth documentation: https://sass-lang.com/documentation/functions/list
    $conditions: set-nth($conditions, $i, parse-expression(nth($conditions, $i)));
  }

  $branches: get-query-branches($conditions);
  $query: '';

  @each $branch in $branches {
    @if (str-length($query) != 0) {
      $query: $query + ', ';
    }

    $query: $query + $branch;
  }

  @media #{$query} {
    @content;
  }
}