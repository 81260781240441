@import 'mixins';
@import '1-tokens/-index'; // design tokens

$border-color: $color-neutral-15;

@mixin data-table-striped-rows($data-table) {
  background: mat.get-color-from-palette($data-table, 'zebra-stripe');
}

@mixin data-table-striped-border-defintions() {
  tr {
    border: 1px solid $border-color;
    border-width: 1px 0;
  }

  tr:first-child {
    border-top-color: transparent;
  }

  tr:last-child {
    border-bottom-color: transparent;
  }
}

@mixin sondermind-system-data-table ($theme, $font) {
  $primary: map-get($theme, primary);

  $foreground: map-get($theme, foreground);
  $data-table: map-get($theme, data-table);

  // these are also in the javascript
  // make sure they're updated in both places
  // column widths are set dynamically, to account for checkbox/button sizing in the rows

  $table-header-padding: 16px 24px 8px;
  $table-header-compressed-top-padding: 4px;
  $table-header-sortable-padding: 16px 17px 8px;
  $table-group-header-padding: 8px 24px;

  $table-cell-padding: 13px 24px 14px;
  $table-cell-compressed-top-padding: 4px;
  $table-cell-mobile-padding: 12px 10px;
  $table-cell-mobile-outside-padding: 16px;
  $table-cell-mobile-outside-groupitem-padding: 32px;
  $table-cell-mobile-outside-groupheader-padding: 24px;

  $table-button-padding: 0 8px;
  $table-button-mobile-padding: 0 8px;

  .sondermind-data-table {
    width: 100%;
    table-layout: fixed;
    border-spacing: $spacing-00;
    border-collapse: collapse;

    &.sm-striped {
      .mat-row:nth-child(even) {
        @include data-table-striped-rows($data-table);
      }

      @include data-table-striped-border-defintions();
    }

    &[data-interactions='true'] {
      > tbody {
        // hover outline
        tr:not([data-group-header]):hover {
          &[data-navigable=true]:not([data-navigable-disabled=true]), &[data-selectable=true] {
            cursor: pointer;
            background-color: $color-neutral-05;
          }
        }

        tr[data-navigable=true][data-navigable-disabled=true] {
          color: mat.get-color-from-palette($foreground, 'disabled')
        }
      }
    }

    // mobile overrides
    &[data-mediaquery~='lt-md'] {
      max-width: 100vw;

      > tbody {
        @include sondermind-typography-level-to-styles($font, data-table-mobile);

        > tr > td {
          padding: $table-cell-mobile-padding;
          vertical-align: top;

          &:first-child {
            padding-left: $table-cell-mobile-outside-padding;
          }

          &:last-child {
            padding-right: $table-cell-mobile-outside-padding;
          }

          &[data-valign='middle'] {
            vertical-align: middle;
          }

          &[data-valign='baseline'] {
            vertical-align: baseline;
          }

          &[data-actions],
          &[data-navigation] {
            padding: $table-button-mobile-padding;
            vertical-align: middle; // default
          }

          // let the navigation button fall through to the row
          &[data-navigation] > button {
            pointer-events: none;
          }
        }

        > tr {
          vertical-align: top;
        }
      }

      > tbody[data-grouped="true"] {
        td:first-child {
          padding-left: $table-cell-mobile-outside-groupitem-padding;
        }

        tr[data-group-header] {
          > td:first-child {
            padding-left: $table-cell-mobile-outside-groupheader-padding;
          }
        }
      }

      > tfoot {
        td {
          padding-left: $spacing-00 !important;
          padding-right: $spacing-00 !important;
        }
      }
    }

    // header rows

    th {
      @include iris-body-bold-responsive;
      user-select: none;

      // when sharing space with bootstrap we have to undo their unscoped th styles
      &[align="left"] { text-align: left; }
      &[align="right"] { text-align: right; }
      &[align="center"] { text-align: center; }
      &[align="justify"] { text-align: justify; }

      padding: $table-header-padding;

      .mat-icon {
        padding-right: $spacing-02;
        position: relative;
        top: 6px;
      }

      &.sortable {
        padding: $table-header-sortable-padding !important;
      }
    }

    > thead[data-compressed='true'] {
      th {
        padding-top: $table-header-compressed-top-padding !important;
        padding-bottom: $table-header-compressed-top-padding !important;
      }
    }

    > thead[data-bleed='true'] {
      th:first-child {
        padding-left: $spacing-00 !important;
      }

      th:last-child {
        padding-right: $spacing-00 !important;
      }
    }

    // body rows

    // zebra or borders (automatic in grouped tables)
    // doesn't apply to headers
    > tbody {
      &[data-zebra='true'][data-grouped='false'] {
        > tr[data-display-row='true']:nth-of-type(2n) {
          @include data-table-striped-rows($data-table);
        }

        > tr[data-display-row='true']:nth-of-type(2n) + tr[data-rollout-row='true'] {
          @include data-table-striped-rows($data-table);
        }
      }

      &[data-bleed="true"] {
        td:first-child {
          padding-left: $spacing-00 !important;
        }

        td:last-child {
          padding-right: $spacing-00 !important;
        }
      }

      &[data-grouped='true'],
      &[data-striped='true'] {
        tr {
          border: 1px solid $border-color;
          border-width: 1px 0;
        }

        tr:first-child {
          border-top-color: transparent;
        }

        tr:last-child {
          border-bottom-color: transparent;
        }
      }

      tr[data-group-header] {
        background: mat.get-color-from-palette($data-table, 'zebra-stripe');
        border-top-color: mat.get-color-from-palette($data-table, 'zebra-stripe');
        border-bottom-color: mat.get-color-from-palette($data-table, 'zebra-stripe');

        td {
          padding: $table-group-header-padding;
        }
      }

      tr[data-rolled-out='true'][data-display-row='true'] {
        border: 2px solid $color-neutral-30;
        border-bottom: none;
        border-radius: $border-radius-02;
      }

      tr[data-rollout-row='true'] {
        border: 2px solid $color-neutral-30;
        border-top: none;
        border-radius: $border-radius-02;
      }

      &[data-compressed='true'] {
        td {
          padding-top: $table-cell-compressed-top-padding !important;
          padding-bottom: $table-cell-compressed-top-padding !important;
        }
      }

      td {
        padding: $table-cell-padding;
        line-height: 24px; // for icon labels

        &[data-valign='middle'] {
          vertical-align: middle;
        }

        &[data-valign='baseline'] {
          vertical-align: baseline;
        }

        &[data-actions],
        &[data-navigation] {
          padding: $table-button-padding;
          vertical-align: middle; // default
        }

        // let the navigation button fall through to the row
        &[data-navigation] > button {
          pointer-events: none;
        }
      }
    }

    // summary data
    tfoot {
      table {
        width: 100%;
        border-top: 1px solid mat.get-color-from-palette($data-table, 'footer-border');

        &[data-mediaquery~='lt-md'] tr[data-summary] {
          td {
            padding: 2px 10px;
          }

          td[data-summary-label] {
            text-align: left;
          }

          td[data-summary-value] {
            @include sondermind-typography-level-to-styles($font, data-table-summary-figure);
          }
        }

        tr[data-summary] {
          text-align: right;
          padding-top: $spacing-04;

          td {
            padding: 6px 12px;
            vertical-align: middle;
          }

          &:first-child td {
            padding-top: $spacing-04;
          }

          td[data-summary-label] {
            @include sondermind-typography-level-to-styles($font, data-table-summary-caption);
            width: 100%;
          }

          td[data-summary-value] {
            @include sondermind-typography-level-to-styles($font, data-table-summary-figure);
          }

          &[data-summary-secondary='true'], &[data-table-secondary=''] {
            opacity: 0.5;

            td[data-summary-value] {
              @include sondermind-typography-level-to-styles($font, data-table-summary-secondary);
            }
          }
        }
      }
    }
  }

  .sondermind-data-table-menu {
    .mat-menu-item {
      display: flex;
      align-items: center;
      min-width: 200px;

      @include sondermind-typography-level-to-styles($font, data-table-menu);
      color: mat.get-color-from-palette($primary, 200);

      span {
        flex: 1 1 auto;
      }

      .mat-icon {
        flex: 0 0 auto;
        margin: $spacing-00;
      }
    }
  }
}
