@import '1-tokens/-index'; // design tokens

@mixin disabledFade() {
    opacity: 1;
    transition: opacity 250ms ease;
    &.sm-disabled {
        opacity: 0.35;
    }
}

@mixin sondermind-system-form-field($theme, $font) {
  $primary: map-get($theme, primary);
  $accent:  map-get($theme, accent);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .sonder-form-field {
    display: block;
    color: inherit;

    position: relative;
    box-sizing: border-box;
    margin-bottom: 16px;

    .sonder-form-field-content, .sonder-form-field-standin {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: stretch;
      justify-content: space-between;

      // after content is relative to the content, not the label or helptext
      position: relative;

      z-index: 1;

      width: 100%;
      box-sizing: border-box;

      border-radius: 5px;

      background: mat.get-color-from-palette($background, 'form-field');
      color: mat.get-color-from-palette($primary, 'default');

      font-size: $font-size-03;
      line-height: 1.7;
      font-weight: $font-weight-normal;

      border: 2px solid transparent;
      border-radius: 5px;
      // background-clip: content-box;

      // individual children types:
      > input, > textarea, > mat-select {
        flex: 1 auto;
        width: 1px; // should grow via flex

        // ie11 doesn't like line-height
        height: 50px;

        font: inherit;
        color: black;

        box-sizing: border-box;
        padding: 10px 20px;
        margin: 0;

        background: none;
        border: none;

        outline: none;

        .mat-select-value {
            color: inherit;
        }

        &:not(.placeholder)::placeholder, .mat-select-placeholder {
            color: mat.get-color-from-palette($foreground, 'form-field');
            font-weight: 400;
        }

        &:invalid {
          box-shadow: none;
       }
      }

      // don't change textarea heights
      > textarea {
        height: auto;
      }

      // datepicker toggle is visible, this just takes unwanted room in the flex
      > .mat-datepicker {
        display: none;
      }

      // mat select is specialized by not being mat-focusable
      // we have to manage and display our own focus/error ring
      > .mat-select {
        border: 1px solid transparent;
        border-radius: 5px;
      }

      > [matsuffix] {
        flex: 0 0 auto;
        order: 1;

        color: mat.get-color-from-palette($accent, 'default');

        // padding: 8px;
        margin-right: 8px;

        &.mat-icon-button {
            padding: 0 8px;
        }
      }

      > [matprefix] {
        flex: 0 0 auto;
        order: -1;

        color: mat.get-color-from-palette($accent, 'default');

        // padding: 8px;
        margin-left: 8px;
      }
    }

    .sonder-form-field-standin {
      padding: 10px 20px;
    }

    .sonder-form-field-type-icon {
      margin-left: 0.5em;
    }

    .sonder-form-field-type-clear {
      margin-right: 0.5em;
    }

    &.sm-focused .sm-mat-focusable {
      border-color: #d9d8d8;
    }

    &.sm-errors .sonder-form-field-content {
      border: 1px solid $color-message-error-base;
      background-color: $color-message-error-tint;
    }

    &.sm-disabled .sonder-form-field-content {
      background: white;
      border-color: #d9d8d8;
    }

    &[data-mediaquery~="gt-sm"] {
      .sonder-form-field-after:not(:empty) {
        position: absolute;
        bottom: 25px; // content is 50px tall, plus 2px bottom border - come up from bottom to ignore label
        left: calc(100% + 12px);
        transform: translateY(50%);
    }
    }

    &[data-mediaquery~="lt-md"] {
      .sonder-form-field-after:not(:empty) {
        margin-top: 8px;
        margin-bottom: 24px;
        height: 0;
      }
    }

    ul.sonder-form-errors {
      display: inline-block;
      text-align: right;
      @include iris-legal;

      @include iris-media("<=xs") {
        @include iris-legal-mobile;
      }
      color: $color-message-error-base !important;

      padding: 0; margin: 0;
      list-style: none;

      z-index: 0;

      li {
          display: inline-block;

          &:not(:empty):not(:last-child):after {
            content: ', ';
            display: inline-block;
            padding-right: 6px;
          }
      }
    }
    @include disabledFade();
  }

  .sonder-form-field-label:not(:empty) {

    .sonder-form-field-label-text {
      @include iris-body-bold-mobile;

      @include iris-media(">xs") {
        @include iris-body-bold;
      }
    }
  }

  .sonder-form-label-standalone {
    @include mat.typography-level($font, 'subheading-2');
    @include sondermind-typography-level-to-styles($font, label);
    color: mat.get-color-from-palette($primary, 'default');
    margin-bottom: 11px;
  }

  .sonder-form-helptext:not(:empty) {
    @include sm-font-small();
    @include disabledFade();

    text-align: right;

    &.left, .left {
        text-align: left;
    }

    a {
        color: mat.get-color-from-palette($primary, 'lighter');
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
  }

  .sonder-form-field-label-required {
    font-size: 50%;
    color: $color-neutral-default;
    @include iris-body-bold-mobile;

    @include iris-media(">xs") {
      @include iris-body-bold;
    }
  }

// when inside a single field, fit into space take by other labels
  .sonder-form-field-helptext .sonder-form-helptext:not(:empty) {
      margin-top: 4px;
      margin-bottom: -1em;
  }

  .sondermind-form-helptext-standalone {
    @include sondermind-typography-level-to-styles($font, form-helptext);
  }

  .sonder-form-field-after:not(:empty) {
    aside {
        line-height: 0;
        margin-right: 1em;
    }


    button {
      // this is because buttons in the label WILL NOT WORK, and will get weird event bubbling
      // use a <a> instead
      background-color: red !important;
    }
  }
}
