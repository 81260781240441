@use 'sass:math';

@mixin sondermind-system-calendar-mini() {
  $dot-size: 24px;
  $border-soft-radius: 5px;

  table.sondermind-calendar-mini {
    table-layout: fixed;

    margin: 0 math.div((-$dot-size), 2);
    width: calc(100% + #{$dot-size});

    th.month-header {
      @include iris-body-bold-responsive;
      padding-left: math.div($dot-size, 2);
    }

    th.day-header, td.day-cell {
      text-align: center;
    }

    td.day-cell,
    td.day-cell .day-cell-content {
      position: relative;
    }

    // establish z-ordering for children
    td.day-cell {
      .day-cell-content {
        z-index: 1;
      }

      &:before { z-index: 0; }
      &:after  { z-index: 2; }
    }

    th .day-header-content, td .day-cell-content {
      @include iris-legal-responsive;

      border-radius: $border-radius-full;
      display: inline-block;
      height: $dot-size;
      width: $dot-size;

      // Override base iris-legal-responsive line-height to accommodate calendar circle
      line-height: $dot-size !important;
    }

    .day-cell.past .day-cell-content,
    .day-cell.other .day-cell-content {
      color: $color-neutral-15;
    }

    // highlights live in the ::before content
    // except for the span, in which case we just color the background directly

    .day-cell.highlightstart {
      .day-cell-content {
        background: $color-brand-2-hover;
      }

      &:before {
        bottom: 0;
        display: block;
        content: '';
        left: 50%;
        position: absolute;
        right: 0;
        top: 0; 

        background:transparentize($color-brand-2-hover, 0.5);
      }
    }

    .day-cell.highlight {
      background: transparentize($color-brand-2-hover, 0.5);

      .day-cell-content {
        background: transparent;
      }
    }

    .day-cell.highlightend {
      .day-cell-content {
        background: $color-brand-2-hover;
      }

      &:before {
        background: transparentize($color-brand-2-hover, 0.5);
        bottom: 0;
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 50%;
        top: 0; 

      }
    }

    // edge border highlight handling
    td.day-cell {
      &:first-child {
        &, &:before {
          border-radius: $border-soft-radius 0 0 $border-soft-radius;
        }
      }

      &:last-child {
        &, &:before {
          border-radius: 0 $border-soft-radius $border-soft-radius 0;
        }
      }
    }

    // busy handling
    // throw a dot pattern in ::after via gradient background
    // limit height based on how busy
    .day-cell.busy {
      &:hover {
        .day-cell-content {
          background-color: $color-neutral-05;
          cursor: pointer;
        }
      }

      &:after {
        display: block;
        content: '';
        height: 0;
        position: absolute;
        top: 3px; 
        right: calc(50% - 15px);
        width: 4px;

        background-image: radial-gradient(currentColor 50%, transparent 0);
        background-size: 4px 4px;
        background-repeat: repeat-y;
      }

      &.busy-1:after { height: 4px; }
      &.busy-2:after { height: 8px; }
      &.busy-3:after { height: 12px; }
    }
  }
}
