@mixin sondermind-system-toast($theme, $font) {
    $primary: map-get($theme, primary);
    $notification: map-get($theme, notification);
    $success: map-get($theme, success);
    $warning: map-get($theme, warn);
    $error: map-get($theme, error);
    $info: map-get($theme, info);

    .sondermind-toast {
        position: relative;
        color: #fff;
        display: flex;

        // separate them from each other when they're adjacent
        & + & {
          margin-top: 5px;
        }

        padding: 10px 16px;
        justify-content: space-between;

        @include sondermind-typography-level-to-styles($font, body-small);
        letter-spacing: 0;
        text-transform: none;

        a {
            color: inherit;
            font-weight: bold;
        }
        > div {
            display: flex;
            align-items: center;
            span {
                padding-left: 13px;
            }
        }
        &.sondermind-error {
            background: mat.get-color-from-palette($error, 'default');
        }
        &.sondermind-success {
            background-color: mat.get-color-from-palette($success, 'default');
        }
        &.sondermind-info {
            background-color: mat.get-color-from-palette($info, 'default');
        }
        &.sondermind-warning {
            background-color: mat.get-color-from-palette($warning, 'default');
        }

        &.sondermind-notification {
            color: mat.get-color-from-palette($primary, 200);
            background-color: mat.get-color-from-palette($notification, 400);

            @include sondermind-typography-level-to-styles($font, body-small);
            letter-spacing: 0;
            text-transform: none;
        }

        .mat-icon:last-child {
            cursor: pointer;
        }
    }
}
