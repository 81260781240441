.iris-h1-desktop { @include iris-h1-desktop; }
.iris-h2-desktop { @include iris-h2-desktop; }
.iris-h3-desktop { @include iris-h3-desktop; }
.iris-h4-desktop { @include iris-h4-desktop; }
.iris-body { @include iris-body; }
.iris-body-bold { @include iris-body-bold; }
.iris-legal { @include iris-legal; }
.iris-button-text { @include iris-button-text; }

.iris-h1-mobile { @include iris-h1-mobile; }
.iris-h2-mobile { @include iris-h2-mobile; }
.iris-h3-mobile { @include iris-h3-mobile; }
.iris-h4-mobile { @include iris-h4-mobile; }
.iris-body-mobile { @include iris-body-mobile; }
.iris-body-bold-mobile { @include iris-body-bold-mobile; }
.iris-legal-mobile { @include iris-legal-mobile; }
.iris-button-text-mobile { @include iris-button-text-mobile; }

.iris-h1-responsive { @include iris-h1-responsive; }
.iris-h2-responsive { @include iris-h2-responsive; }
.iris-h3-responsive { @include iris-h3-responsive; }
.iris-h4-responsive { @include iris-h4-responsive; }
.iris-body-responsive { @include iris-body-responsive; }
.iris-body-bold-responsive { @include iris-body-bold-responsive; }
.iris-legal-responsive { @include iris-legal-responsive; }
.iris-button-text-responsive { @include iris-button-text-responsive; }

.iris-modal-h1-responsive { @include iris-modal-h1-responsive; }
.iris-modal-h2-responsive { @include iris-modal-h2-responsive; }
.iris-modal-h3-responsive { @include iris-modal-h3-responsive; }