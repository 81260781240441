@use './layers' as layers;

@import 'theme';
@import 'design-tokens.scss';
@import 'typography';
@import '2-tools/_tools.helpers';
@import '2-tools/_tools.breakpoints';


@mixin sondermind-system-side-nav() {
  $width: 100%;
  $height: 100%;

  .sondermind-side-nav {
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: $height;
    width: $width;
    background: $color-brand-1-tint;
    border-right: 1px solid $color-neutral-15;

    @include layers.navigation();

    hr {
      width: 100%;
      height: 1px;
      margin-top: $spacing-00;
      margin-bottom: $spacing-00;
      border-bottom: 1px solid $color-neutral-15;
    }

    .logo-container {
      padding: $spacing-03;

      img {
        width: 100%;
      }
    }

    .mobile-header {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: $spacing-05;
      padding: $spacing-03;
      align-items: center;
      border-bottom: 1px solid $color-neutral-15;

      &.open {
        border-bottom: none;
      }

      mat-icon, svg #Logo-Mark {
        fill: $color-brand-1-base;
        color: $color-brand-1-base;
        height: $spacing-05;
        width: $spacing-05;
      }
    }

    sondermind-side-nav-menu-item {
      cursor: pointer;

      .item-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        max-height: $spacing-08;
        padding: $spacing-03;
        overflow-wrap: break-word;
        margin: $spacing-00 $spacing-02;
        border-radius: $spacing-01;

        // Provider info
        .provider {
          .provider-info {
            display: flex;
            justify-content: center;

            .item-label {
              height: inherit;
              margin-bottom: $spacing-02;
              margin-top: $spacing-01;
              color: $color-brand-1-base;
            }

            .iris-icon {
              margin-top: $spacing-01;

              path {
                fill: $color-brand-1-base;
              }
            }
          }

          .item-status {
            @include iris-legal;
            line-height: $font-line-height-02;
            color: $color-brand-1-base;
          }
        }

        .item-label {
          @include iris-body-bold;
        }

        .item-label, .item-status, .mat-icon {
          color: $color-neutral-60;
        }

        .item-badge {
          background-color: $color-brand-1-hover;
          color: $color-neutral-white;
          height: $spacing-03;
          width: $spacing-03;
          font: $font-weight-semibold $font-size-02 $font-family-proxima-nova;
          line-height: $font-line-height-02;
          text-align: center;
          border-radius: 50%;
          padding: 2px;
        }

        .left-items {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .left-items-header {
            margin: auto;
          }
        }

        &:hover {
          .item-status, .item-label, .mat-icon {
            color: $color-neutral-default;
          }

          .provider {
            .item-status {
              color: $color-brand-1-base;
            }
          }
        }
      }

      &[iconOnRight="true"] {
        mat-icon {
          padding-right: 0px;
        }
      }

      &[size="sub"] {
        .item-container {
          max-height: 35px;
          padding-top: $spacing-03;
          padding-bottom: $spacing-03;
        }
      }

      &.router-link-active {
        .item-container {
          background-color: #DAE3E6;

          .item-label {
            color: $color-brand-1-base;
          }

          mat-icon {
            color: $color-brand-1-base;
          }
        }
      }

      // Sub menu item
      sondermind-side-nav-menu-item {
        .item-container {
          background-color: $color-neutral-white;
          margin: $spacing-00;
          border-radius: $spacing-00;

          .item-label {
            margin-left: $spacing-02;
          }
          .item-label, .item-status, .mat-icon {
            color: $color-neutral-80;
          }
        }

        &.router-link-active {
          .item-container {
            background-color: $color-neutral-white;
            border-left: $spacing-01 solid $color-brand-1-base;
            margin: $spacing-00;
            border-radius: $spacing-00;

            .item-label {
              color: $color-brand-1-base;
              margin-left: $spacing-01;
            }

            mat-icon {
              color: $color-brand-1-base;
            }
          }
        }
      }
    }
  }
}
